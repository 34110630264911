import * as FixLinks from './fix-in-page-links';
import ShopifyBuy from '@shopify/buy-button-js';

const defaultComponentOptions = {
  product: {
    iframe: false,
    styles: {
      product: {
        '@media (min-width: 601px)': {
          'max-width': 'calc(25% - 20px)',
          'margin-left': '20px',
          'margin-bottom': '50px',
        },
      },
      title: {
        color: '#111111',
      },
      quantityInput: {
        'font-size': '16px',
        'padding-top': '16px',
        'padding-bottom': '16px',
      },
      price: {
        color: '#111111',
      },
      compareAt: {
        color: '#111111',
      },
      unitPrice: {
        color: '#111111',
      },
    },
    contents: {
      img: false,
      title: false,
      price: false,
    },
    text: {
      button: 'Add to cart',
    },
  },
  productSet: {
    styles: {
      products: {
        '@media (min-width: 601px)': {
          'margin-left': '-20px',
        },
      },
    },
  },
  toggle: {
    styles: {
      toggle: {
        'background-color': '#404837',
        ':hover': {
          'background-color': '#3a4132',
        },
        ':focus': {
          'background-color': '#3a4132',
        },
      },
      count: {
        'font-size': '16px',
      },
    },
  },
  lineItem: {
    styles: {
      variantTitle: {
        color: '#111111',
      },
      title: {
        color: '#111111',
      },
      price: {
        color: '#111111',
      },
      fullPrice: {
        color: '#111111',
      },
      discount: {
        color: '#111111',
      },
      discountIcon: {
        fill: '#111111',
      },
      quantity: {
        color: '#111111',
      },
      quantityIncrement: {
        color: '#111111',
        'border-color': '#111111',
      },
      quantityDecrement: {
        color: '#111111',
        'border-color': '#111111',
      },
      quantityInput: {
        color: '#111111',
        'border-color': '#111111',
      },
    },
  },
  cart: {
    styles: {
      button: {
        'font-size': '16px',
        'padding-top': '16px',
        'padding-bottom': '16px',
        ':hover': {
          'background-color': '#3a4132',
        },
        'background-color': '#404837',
        ':focus': {
          'background-color': '#3a4132',
        },
        'border-radius': '4px',
      },
      title: {
        color: '#111111',
      },
      header: {
        color: '#111111',
      },
      lineItems: {
        color: '#111111',
      },
      subtotalText: {
        color: '#111111',
      },
      subtotal: {
        color: '#111111',
      },
      notice: {
        color: '#111111',
      },
      currency: {
        color: '#111111',
      },
      close: {
        color: '#111111',
        ':hover': {
          color: '#111111',
        },
      },
      empty: {
        color: '#111111',
      },
      noteDescription: {
        color: '#111111',
      },
      discountText: {
        color: '#111111',
      },
      discountIcon: {
        fill: '#111111',
      },
      discountAmount: {
        color: '#111111',
      },
    },
    // Open in the same window, not in a popup
    popup: false,
    text: {
      total: 'Subtotal',
      button: 'Checkout',
      closeAccessibilityLabel: 'Close cart',
    },
    templates: {
      // Fix for wrong accessible name in the default template
      title: `<div class="{{data.classes.cart.header}}" data-element="cart.header">
            <h2 class="{{data.classes.cart.title}}" data-element="cart.title">{{data.text.title}}</h2>
            <button class="{{data.classes.cart.close}}" data-element="cart.close">
              <span aria-hidden="true">&times;</span>
              <span class="visuallyhidden">{{data.text.closeAccessibilityLabel}}</span>
             </button>
          </div>`,
    },
  },
};

/* The main entry point of the application */
const init = () => {
  FixLinks.init();

  const client = ShopifyBuy.buildClient({
    domain: 'peftasteri.myshopify.com',
    storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  });

  // Create the Shopify UI (e.g. persistent cart layer)
  const ui = ShopifyBuy.UI.init(client);

  // Create Shopify buy buttons
  const shopifyNodes = document.querySelectorAll('[data-shopify-type]');

  // TODO: try to target a known node?
  const cartToggleNode = document.querySelector('[data-shopify-cart-toggle]');

  console.log({ cartToggleNode });

  // Always render the cart (if it has items, even if the page does not have a product/collection/component)
  // No node specified means that the default one will be used for the cart modal / flyout
  ui.createComponent('cart', {
    moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
    debug: process.env.NODE_ENV !== 'production',
    // Render the toggle inside the designated node, instead of the sticky one
    toggles: [{ node: cartToggleNode }],
    options: {
      ...defaultComponentOptions,
      toggle: {
        iframe: false,
        sticky: false,
        text: {
          countAccessibilityLabel: 'Items',
          title: 'cart',
        },
        templates: {
          title:
            '<h5 class="{{data.classes.toggle.title}}" data-element="toggle.title">{{data.text.title}}</h5>',
          icon:
            '<svg xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" class="{{data.classes.toggle.icon}}" data-element="toggle.icon" viewBox="0 0 25 25" enable-background="new 0 0 25 25"><g class="{{data.classes.toggle.iconPath}}"><path d="M24.6 3.6c-.3-.4-.8-.6-1.3-.6h-18.4l-.1-.5c-.3-1.5-1.7-1.5-2.5-1.5h-1.3c-.6 0-1 .4-1 1s.4 1 1 1h1.8l3 13.6c.2 1.2 1.3 2.4 2.5 2.4h12.7c.6 0 1-.4 1-1s-.4-1-1-1h-12.7c-.2 0-.5-.4-.6-.8l-.2-1.2h12.6c1.3 0 2.3-1.4 2.5-2.4l2.4-7.4v-.2c.1-.5-.1-1-.4-1.4zm-4 8.5v.2c-.1.3-.4.8-.5.8h-13l-1.8-8.1h17.6l-2.3 7.1z"/><circle cx="9" cy="22" r="2"/><circle cx="19" cy="22" r="2"/></g></svg>',
          count:
            '<div class="{{data.classes.toggle.count}}" data-element="toggle.count" data-count="{{data.count}}"><span class="shopify-buy--visually-hidden">{{data.text.countAccessibilityLabel}} </span>{{data.count}}</div>',
        },
        events: {
          // TODO: On close, focus the opening toggle node
          // TODO: On open, `inert` the rest of the document, On close, un-`inert` it
          // After the component has renderd, attach a Click/Space/Enter handler to the top-level noe
          afterRender: (component) => {
            component.node.addEventListener('click', (ev) => {
              // ui.toggleCart() did not open the drawer, so... click on the node it is
              // If the child node uses event delegation, we must check it is not the target
              if (ev.target !== ev.currentTarget) {
                return;
              }
              // Toggle cart, do not propagate upward (because otherwise the delegated hanlder on the body will close the cart again -_-)
              ui.toggleCart();
              ev.stopPropagation();
            });

            // Remove the placeholder
            const placeholderNode = component.node.querySelector(
              '.placeholder'
            );
            if (placeholderNode !== null) {
              placeholderNode.remove();
            }
          },
        },
      },
    },
  });

  for (let node of shopifyNodes) {
    const type = node.dataset.shopifyType;
    const id = node.dataset.shopifyId;
    const handle = node.dataset.shopifyHandle;
    const placeholderNode = node.querySelector(
      '.shopify-buy-button__placeholder'
    );

    if (!type) {
      throw new Error('data-shopify-type must be specified');
    }

    // The ids in the Storefront API do not seem to match those expected by buy-button-js
    // To fix this, we query by handle (which matches up) instead
    if (type === 'product' && !handle && !id) {
      throw new Error(
        'You must specifiy either data-shopify-id or data-shopify-handle for products'
      );
    }

    if (process.env.NODE_ENV !== 'production') {
      console.info('Creating Shopify component', { type, id, handle, node });
    }

    ui.createComponent(type, {
      // The ids from the Storefront API are normalised and base64'd
      // Thus, we provide them as 'storefrontId', for the script to denormalise
      // e.g. Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzEwMDc5Nzg1MTAw -> gid://shopify/Product/10079785100 -> 10079785100
      // The docs for the buy-button-js library do not mention this, but the code and other docs do
      // @see https://shopify.dev/docs/storefront-api/reference/scalar
      // If this breaks in the future, consider passing 'id' and doing the denormalisation manually
      storefrontId: id,
      handle,
      node,
      moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
      debug: process.env.NODE_ENV !== 'production',
      options:
        type === 'product'
          ? {
              ...defaultComponentOptions,
              product: {
                ...defaultComponentOptions.product,
                events: {
                  // Hide the placeholder after the component renders
                  afterRender: (component) => {
                    if (placeholderNode !== null) {
                      placeholderNode.remove();
                    }
                  },
                },
              },
            }
          : {},
    });
  }
};

init();
